import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import SEO from '../seo';
import Layout from '../layout';
import MainCol from '../PageBody/MainCol';
import ColumnWrapper from '../PageBody/styled/ColumnWrapper';
import PageContainer from '../layout-components/PageContainer';
import NewsList from './NewsList';
import NewsSubnav from '../NewsComponents/NewsSubnav';
import PriceFeeds from '../PriceFeeds';
import { GatsbyIpfFileProvider } from '../../context/GatsbyIpfFileContext';
import RichTextWithDictionary from '../RichTextWithDictionary';
import Pagination from './Pagination';
import LatestArticles from './LatestArticles';
import linkResolver from '../../utils/linkResolver';

const StyledColumnWrapper = styled(ColumnWrapper)`
  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    padding: 0 1rem;
  }

  @media (max-width: 1600px) {
    padding: 0 3.75rem;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding-right: 28.5rem;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    padding-right: 32.5rem;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    &&& {
      padding-top: 1rem;
    }
  }
`;

const Header = styled.h1`
  font-size: 2.1875rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 7.8rem;
  }

  a {
    color: ${prop('theme.colors.black')};

    &:hover {
      color: ${prop('theme.colors.black')};
      text-decoration-color: ${prop('theme.colors.redPrimary')};
    }
  }
`;

const Message = styled.div`
  margin-bottom: 1rem;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-bottom: 3rem;
  }
`;

const BackArrow = styled.div`
  font-size: 1.25rem;
  vertical-align: center;
  line-height: 24px;
  margin-right: 0.5rem;
  padding-bottom: 3px;

  &:hover {
    text-decoration: none !important;
  }
`;

const PaginationContainer = styled.div`
  margin-bottom: 1rem;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-left: 10rem;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-bottom: 2rem;
  }
`;

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  uid: PropTypes.string,
  type: PropTypes.string,
  newsItems: PropTypes.array,
  pagination: PropTypes.object,
  latestArticles: PropTypes.array
};

const defaultProps = {
  title: '',
  description: {},
  uid: '',
  type: '',
  newsItems: [],
  pagination: null,
  latestArticles: []
};

function NewsListPage({
  title,
  description,
  uid,
  type,
  newsItems,
  pagination,
  latestArticles
}) {
  return (
    <Layout>
      <PageContainer>
        <GatsbyIpfFileProvider>
          <MainCol>
            <StyledColumnWrapper>
              <NewsSubnav pageUid={uid} />
              <Header>
                <Link to={linkResolver({ uid: uid, type: type })}>{title}</Link>
              </Header>
              {description && description.text && (
                <RichTextWithDictionary render={description.richText} />
              )}
              {newsItems && newsItems.length > 0 ? (
                <>
                  <NewsList
                    newsList={newsItems}
                    parentPage={{ uid: uid, type: type }}
                    teaserSetting='full'
                    displayMoreLink
                    showDividers
                    fullPageMode
                  />
                  {pagination && (
                    <PaginationContainer>
                      <Pagination
                        currentPage={pagination.currentPage}
                        pageSkip={pagination.skip}
                        pageLimit={pagination.limit}
                        numberOfPages={pagination.numberOfPages}
                        pageType={type}
                        pageUid={uid}
                        totalItemCount={pagination.articleCount}
                      />
                    </PaginationContainer>
                  )}
                </>
              ) : (
                <div>
                  <Message>No articles were found.</Message>
                  <StyledLink to='/news'>
                    <BackArrow>←</BackArrow> Return to News Homepage
                  </StyledLink>
                </div>
              )}
            </StyledColumnWrapper>
          </MainCol>
          <PriceFeeds title='news-article-type' />
        </GatsbyIpfFileProvider>
      </PageContainer>
      <LatestArticles articles={latestArticles} />
    </Layout>
  );
}

NewsListPage.propTypes = propTypes;
NewsListPage.defaultProps = defaultProps;

export default NewsListPage;
