import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import paginationArrowRight from '../../images/pagination-arrow-right.svg';
import paginationArrowLeft from '../../images/pagination-arrow-left.svg';
import linkResolver from '../../utils/linkResolver';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PaginationButton = styled(Link)`
  padding-bottom: 1px;

  &:first-child {
    padding-right: 20px;
  }

  &:last-child {
    padding-left: 20px;
  }
`;

const PageNumber = styled(Link)`
  color: ${prop('theme.colors.black')};
  padding-right: 20px;

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    color: ${prop('theme.colors.black')};
    text-decoration-color: ${prop('theme.colors.redPrimary')};
  }

  ${ifProp(
    { $active: true },
    css`
      color: ${prop('theme.colors.redPrimary')};
      font-weight: ${prop('theme.fontWeights.semiBold')};

      &:hover {
        color: ${prop('theme.colors.redPrimary')};
      }
    `
  )}
`;

export const PageNumberContainer = styled.div`
  display: flex;

  div {
    padding-right: 20px;
  }
`;

const propTypes = {
  currentPage: PropTypes.number,
  pageSkip: PropTypes.number,
  pageLimit: PropTypes.number,
  numberOfPages: PropTypes.number,
  pageType: PropTypes.string,
  pageUid: PropTypes.string,
  totalItemCount: PropTypes.number,
  linkType: PropTypes.string
};

const defaultProps = {
  currentPage: 0,
  pageSkip: 0,
  pageLimit: 0,
  numberOfPages: 0,
  pageType: '',
  pageUid: '',
  totalItemCount: 0,
  linkType: 'default'
};

function Pagination({
  currentPage,
  pageSkip,
  pageLimit,
  numberOfPages,
  pageType,
  pageUid,
  totalItemCount,
  linkType = 'default'
}) {
  const startOfRange =
    currentPage > 4 ? Math.min(currentPage - 2, numberOfPages - 4) : 1;
  const endOfRange =
    currentPage > 3
      ? Math.min(currentPage + 2, numberOfPages)
      : Math.min(5, numberOfPages);
  const pageRange = Array(endOfRange - startOfRange + 1)
    .fill()
    .map((_, i) => startOfRange + i);

  const getLink = (pageNumber) =>
    linkType === 'queryParameter'
      ? `${pageUid}page=${pageNumber}`
      : linkResolver({
          type: pageType,
          uid: pageUid,
          pageNumber: pageNumber
        });

  return (
    <Container>
      <ButtonContainer>
        {pageSkip > 0 && (
          <PaginationButton to={getLink(currentPage - 1)}>
            <img src={paginationArrowLeft} />
          </PaginationButton>
        )}
        <PageNumberContainer>
          {startOfRange > 1 && (
            <>
              <PageNumber to={getLink(1)}>1</PageNumber>
              <div>...</div>
            </>
          )}
          {pageRange.map((page) => (
            <PageNumber
              key={`page_number_${page}`}
              $active={page === currentPage}
              to={getLink(page)}
            >
              {page}
            </PageNumber>
          ))}
          {endOfRange < numberOfPages && (
            <>
              <div>...</div>
              <PageNumber to={getLink(numberOfPages)}>
                {numberOfPages}
              </PageNumber>
            </>
          )}
        </PageNumberContainer>
        {pageSkip + pageLimit < totalItemCount && (
          <PaginationButton to={getLink(currentPage + 1)}>
            <img src={paginationArrowRight} />
          </PaginationButton>
        )}
      </ButtonContainer>
      <div>
        {`${pageSkip + 1}-${Math.min(
          pageSkip + pageLimit,
          totalItemCount
        )} of ${totalItemCount}`}
      </div>
    </Container>
  );
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
