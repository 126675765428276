import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NewsListPage from '../components/NewsComponents/NewsListPage';
import SEO from '../components/seo';

const propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
};

const defaultProps = {
  data: null,
  pageContext: {}
};

function NewsCategoryPage({ data: queryResult, pageContext }) {
  const allNews = get(queryResult, 'allPrismicNewsItem.edges', []);
  const latestArticles = get(queryResult, 'latestArticles.edges', []);
  const { category_title, description } = queryResult.prismicNewsCategory.data;

  return (
    <NewsListPage
      title={category_title}
      description={description}
      type={queryResult.prismicNewsCategory.type}
      uid={queryResult.prismicNewsCategory.uid}
      newsItems={allNews}
      pagination={pageContext}
      latestArticles={latestArticles}
    />
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicNewsCategory.data;

  if (!pageData) {
    return null;
  }

  const {
    seo_title,
    seo_meta_description,
    seo_image,
    category_title
  } = pageData;

  return (
    <SEO
      title={seo_title || category_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query NewsCategoryPageQuery(
    $id: String!
    $uid: String!
    $skip: Int!
    $limit: Int!
  ) {
    prismicNewsCategory(id: { eq: $id }) {
      uid
      type
      _previewable
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        category_title
        description {
          text
          richText
        }
      }
    }
    allPrismicNewsCategory(sort: { fields: data___subnav_order, order: ASC }) {
      edges {
        node {
          type
          uid
          data {
            category_title
          }
        }
      }
    }
    allPrismicNewsItem(
      filter: {
        data: {
          post_categories: { elemMatch: { category: { uid: { eq: $uid } } } }
        }
      }
      sort: { fields: data___post_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          type
          uid
          ...NewsInfo
        }
      }
    }
    latestArticles: allPrismicNewsItem(
      sort: { fields: data___post_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...NewsInfo
        }
      }
    }
  }
`;

NewsCategoryPage.propTypes = propTypes;
NewsCategoryPage.defaultProps = defaultProps;

export default withPrismicPreview(NewsCategoryPage);
